<template>
  <div class="container">
    <table-page dataName="areas"
                :search="search"
                ref="table"
                @on-success="fetchSuccess"
                :request="fetchNets">
      <template slot="button">

        <el-button type="primary"
                   v-permission="['ADMIN']"
                   class="mr-15"
                   @click="$router.push({name: 'SetNet'})">新增网点</el-button>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-if="exportUrl"
                   @click="exportNetsData">导出网点数据</el-button>
        <FileUpload action="/api/mall/admin/network/import"
                    @on-success="fileUploadSuccess"
                    v-permission="['ADMIN']"
                    title="网点导入" />
      </template>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="名称">
      </el-table-column>
      <el-table-column prop="level"
                       show-overflow-tooltip
                       label="级别">
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column prop="s_address"
                       show-overflow-tooltip
                       label="活动码">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="getEntry('pages/activity/activityList','area_id='+scope.row.id)">活动码</el-button>
        </template>

      </el-table-column>
      <el-table-column prop="s_address"
                       show-overflow-tooltip
                       label="网点码">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="getEntry('pages/other/netCode','area_id='+scope.row.id)">网点码</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="s_address"
                       show-overflow-tooltip
                       label="网点主页码">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="getEntry('pages/other/netVouchers','area_id='+scope.row.id)">网点主页码</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="setNet(scope.row.id)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     v-permission="['ADMIN']"
                     icon="el-icon-delete"
                     @click="delNet(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
    <MiniCode ref="mini" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import MiniCode from '@/components/MiniCode'
import FileUpload from '@/components/FileUpload'
import { fetchNets, delNet } from '@/api/system'
export default {
  name: 'Stores',
  data () {
    return {
      exportUrl: '',
      fetchNets,
      search: [{
        type: 'area'
      }]
    }
  },
  components: {
    TablePage,
    FileUpload,
    MiniCode
  },
  methods: {
    fileUploadSuccess (res) {
      if (res.meta.code === 0) {
        if (res.data.status === 'SUCCESS') {
          this.$alert('导入成功')
          this.$refs.table.fetch()
        } else {
          this.$alert(res.data.err_msg, '导入情况')
        }
      } else {
        this.$alert(res.meta.msg)
      }
    },
    getEntry (page, scene) {
      this.$refs.mini.createMpCode({
        page: page,
        scene: scene
      })
    },
    exportNetsData () {
      window.open(this.exportUrl, '_blank')
    },
    setNet (id) {
      this.$router.push({
        name: 'SetNet',
        query: {
          id: id
        }
      })
    },
    delNet (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delNet({
          area_ids: [e.id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
    }

  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
